import {ButtonGroup, IconButton, InputNumber, SelectPicker} from "rsuite";
import React from "react";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";


const ProductItem = ({ rowValue = {}, onChange, rowIndex, rowError,productList }) => {
    const handleChangeName = value => {
        onChange(rowIndex, { ...rowValue, id: value });
    };
    const handleChangeAmount = value => {
        onChange(rowIndex, { ...rowValue, qty: value });
    };

    return (
        <tr>
            <Cell>
                <SelectPicker value={rowValue.id} onChange={handleChangeName} style={{ width: 196 }}  data={productList}/>
                {rowError ? <ErrorMessage>{rowError.id.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <InputNumber
                    min={0}
                    value={rowValue.qty}
                    onChange={handleChangeAmount}
                    style={{ width: 100 }}
                />
                {rowError ? <ErrorMessage>{rowError.qty.errorMessage}</ErrorMessage> : null}
            </Cell>
        </tr>
    );
};



const ErrorMessage = ({ children }) => <span style={{ color: 'red' }}>{children}</span>;
const Cell = ({ children, style, ...rest }) => (
    <td style={{ padding: '2px 4px 2px 0', verticalAlign: 'top', ...style }} {...rest}>
        {children}
    </td>
);
const ProductInputControl = ({ value = [], onChange, fieldError ,productList}) => {
    const errors = fieldError ? fieldError.array : [];
    const [products, setProducts] = React.useState(value);
    const handleChangeProducts = nextProducts => {
        setProducts(nextProducts);
        onChange(nextProducts);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextProducts = [...products];
        nextProducts[rowIndex] = value;
        handleChangeProducts(nextProducts);
    };

    const handleMinus = () => {
        handleChangeProducts(products.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeProducts(products.concat([{ id: '', qty: null }]));
    };
    return (
        <table>
            <thead>
            <tr>
                <Cell>Product</Cell>
                <Cell>Quantity</Cell>
            </tr>
            </thead>
            <tbody>
            {products.map((rowValue, index) => (
                <ProductItem
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                    productList={productList}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{ paddingTop: 10 }}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon />} />
                        <IconButton onClick={handleMinus} icon={<MinusIcon />} />
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

export default ProductInputControl;