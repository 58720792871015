import {Schema} from "rsuite";
const {StringType, NumberType, ArrayType, ObjectType, DateType} = Schema.Types;

const StockCreateModel = Schema.Model({
    deal_stock_name: StringType().isRequired('This field is required.'),
    stock_weight: NumberType()
        .range(0.1, 1000, 'Stock Weight must be between 0.1 and 1000000.')
        .isRequired('This field is required.'),
    purchase_price: NumberType()
        .range(1, 1000000, 'Purchase Price must be between 1 and 1000000.')
        .isRequired('This field is required.'),
    dc_charges: NumberType()
        .range(0, 1000000, 'DC Charges must be between 0 and 1000000.')
        .isRequired('This field is required.'),
    seller_name: StringType().isRequired('This field is required.'),
    seller_phone: StringType().isRequired('This field is required.')
});

export default StockCreateModel;