// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAfb77fiYuDLSIyAIXNukWTiDBgO6H48uw",
  authDomain: "order-registration-80266.firebaseapp.com",
  projectId: "order-registration-80266",
  storageBucket: "order-registration-80266.appspot.com",
  messagingSenderId: "124676874651",
  appId: "1:124676874651:web:d1e8940edce1e541ed013e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore();

export {auth,firestore}