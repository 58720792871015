
import {auth} from "../../firebase";
import React, {useState, useEffect, Fragment, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {getAuth} from "firebase/auth";
import SignIn from "../../pages/auth/sigin";


const CheckLogin = (props) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();
    const { child } = props;

    console.log(user);
    if (user === null) {
        navigate("/login");
        return <SignIn />
        // return null;
    }

    return (
        <Fragment>
            {child}
        </Fragment>
    );
}

export default CheckLogin;