import {Button, Drawer,Placeholder} from "rsuite";

const CustomDrawer = ({backdrop,size,open, setOpen, placement, title,children}) => {
    return (
        <Drawer size={size} backdrop={backdrop}  placement={placement} title={title} open={open} onClose={() => setOpen(false)}>
            <Drawer.Header>
                <Drawer.Title>{title}</Drawer.Title>
                <Drawer.Actions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
                {children}
            </Drawer.Body>
        </Drawer>
    );
}

export default CustomDrawer;