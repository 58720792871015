import React from 'react';
import {Navbar, Nav, Tooltip} from 'rsuite';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import CogIcon from "@rsuite/icons/legacy/Cog";
import ExitIcon from '@rsuite/icons/Exit';
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from "../../firebase";

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
        <Nav>
            <Nav.Item title='Sign Out' tooltip icon={<ExitIcon/>} onClick={() => signOut(auth)}>
                <Tooltip style={{ width: 120 }}>
                    This is a <i>tooltip</i> .
                </Tooltip>
                {expand ? 'Sign out' : ''}
            </Nav.Item>
        </Nav>
        <Nav>
            {/*<Nav.Menu
                noCaret
                placement="topStart"
                trigger="click"
                title={<CogIcon style={{ width: 20, height: 20 }} size="sm" />}
            >
                <Nav.Item>Settings</Nav.Item>
                <Nav.Item>Sign out</Nav.Item>
            </Nav.Menu>*/}
        </Nav>
      <Nav pullRight>
        <Nav.Item
          onClick={onChange}
          style={{ textAlign: 'center' }}
          icon={expand ? <ArrowLeftLineIcon /> : <ArrowRightLineIcon />}
        />
      </Nav>
    </Navbar>
  );
};

export default NavToggle;
