import {Col, Divider, Grid, Panel, Row, toaster} from "rsuite";
import React, {useRef, useState} from "react";
import {Form, ButtonToolbar, Button, Input} from 'rsuite';
import {auth} from "../../firebase";
import {InsertSingle, getAll} from "../../servies/firestore_service";
import ToasterMessage from "../../components/common/toaster_message";
import ProductCreateModel from "../../form_models/product_create_model";
import {customerCollectionName, productCollectionName} from "../../config";
import Customer_create_model from "../../form_models/customer_create_model";
import CustomerCreateModel from "../../form_models/customer_create_model";


const CustomerCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const defaultFormValue = {
        customer_name: '',
        phone: '',
        address: '',
        city: '',
    }
    const [formData, setFormData] = useState(defaultFormValue);

    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formData, 'Form Value')
    };


    const handleSubmit = async (e) => {

        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        setIsLoading(true);
        toaster.clear();

        InsertSingle(formData,customerCollectionName).then((docRef) => {
            console.log(docRef);
            ToasterMessage.success('Stock Created Successfully');
            setFormData(defaultFormValue);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            ToasterMessage.error('Error in creating Stock');
            setIsLoading(false);
        });

    }


    return (
        <Panel header={<h3 className="title">Create Customer</h3>}>
            <Form
                ref={formRef}
                onCheck={setFormError}
                formValue={formData}
                onChange={setFormData}
                model={CustomerCreateModel}
                disabled={isSubmitted}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="customer_name">
                                <Form.ControlLabel>Customer Name</Form.ControlLabel>
                                <Form.Control name="customer_name" type="text" value={formData.customer_name}/>
                                <Form.HelpText tooltip>Customer Name is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="phone">
                                <Form.ControlLabel>Phone</Form.ControlLabel>
                                <Form.Control name="phone" value={formData.phone}/>
                                <Form.HelpText tooltip>Phone is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="address">
                                <Form.ControlLabel>Address</Form.ControlLabel>
                                <Form.Control name="address" value={formData.address}/>
                                <Form.HelpText tooltip>Address is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="city">
                                <Form.ControlLabel>city</Form.ControlLabel>
                                <Form.Control name="city"  value={formData.city}/>
                                <Form.HelpText tooltip>City is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={handleSubmit}
                                            loading={isLoading}>Submit</Button>
                                    <Button appearance="default" onClick={(e)=>setFormData(defaultFormValue)}>Reset</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>

        </Panel>
    )
}

export default CustomerCreate;