import {Button, DOMHelper, Panel, Table, Drawer,ButtonToolbar, IconButton, Placeholder} from 'rsuite';
import React, {useEffect, useState} from "react";
import {getSingle, getAll} from "../../servies/firestore_service";
import {customerCollectionName, productCollectionName} from "../../config";
import CustomDrawer from "../../components/common/custom_drawer";
import CustomerCreate from "./customer_create";

const CustomerList = () => {
    const {Column, HeaderCell, Cell} = Table;
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [data, setData] = useState([]);
    const {getHeight} = DOMHelper;
    const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />;


    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    useEffect(() => {
        setIsLoading(true)
        getAll(customerCollectionName).then((docs) => {

            console.log(docs);
            setData(docs);
            setIsLoading(false)
        }).catch((error) => {
            console.log(error);
        })

    }, [open])


    return (
        <Panel header={<h3 className="title">Customers List</h3>}>
            <Button appearance='primary' Style='margin-bottom: 10px' onClick={() => handleOpen('bottom')}>Create Customer</Button>
            <CustomDrawer backdrop={'static'} size={'calc(100% - 80px)'} placement={placement} open={open} setOpen={setOpen} title='Create Customer'>
                <CustomerCreate/>
            </CustomDrawer>
            <Table
                virtualized
                height={Math.max(getHeight(window) - 300, 400)}
                // rowHeight={34}
                bordered
                data={data}
                translate3d={false}
                loading={isLoading}
            >
                <Column width={130}>
                    <HeaderCell>Customer Name</HeaderCell>
                    <Cell dataKey="customer_name"/>
                </Column>

                <Column width={130}>
                    <HeaderCell>Phone</HeaderCell>
                    <Cell dataKey="phone"/>
                </Column>

                <Column width={130} fixed fullText>
                    <HeaderCell>Address</HeaderCell>
                    <CompactCell dataKey="address"/>

                </Column>

                <Column width={100}>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city"/>
                </Column>


                <Column width={80} fixed="right">
                    <HeaderCell>Action</HeaderCell>

                    <Cell style={{padding: '6px'}}>
                        {rowData => (
                            <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                Edit
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>
        </Panel>
    )
}

export default CustomerList;
