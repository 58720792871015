import {Schema} from "rsuite";
const {StringType, NumberType, ArrayType, ObjectType, DateType} = Schema.Types;

const CustomerCreateModel = Schema.Model({
    customer_name: StringType().isRequired('This field is required.'),
    phone: StringType().isRequired('This field is required.'),
    address: StringType().isRequired('This field is required.'),
    city: StringType().isRequired('This field is required.'),
});

export default CustomerCreateModel;