import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Container, Sidebar, Sidenav, Content, Nav, DOMHelper } from 'rsuite';
import { Outlet } from 'react-router-dom';
import NavToggle from './NavToggle';
import Header1 from '../Header';
import NavLink from '../NavLink';
import Brand from '../Brand';

const { getHeight, on } = DOMHelper;

const NavItem = props => {
    const { title, eventKey, ...rest } = props;
    return (
        <Nav.Item eventKey={eventKey} as={NavLink} {...rest}>
            {title}
        </Nav.Item>
    );
};

const Frame = (props) => {
    const { navs } = props;
    const [expand, setExpand] = useState(true);
    const [windowHeight, setWindowHeight] = useState(getHeight(window));

    useEffect(() => {
        setWindowHeight(getHeight(window));
        const resizeListenner = on(window, 'resize', () => setWindowHeight(getHeight(window)));

        return () => {
            resizeListenner.off();
        };
    }, []);

    const containerClasses = classNames('page-container', {"container-full": !expand});

    const navBodyStyle = expand ? { height: windowHeight - 112, overflow: 'auto' } : {};

    return (
        <Container className="frame">
            <Sidebar
                style={{ display: 'flex', flexDirection: 'column' }}
                width={expand ? 260 : 56}
                collapsible
            >
                <Sidenav.Header>
                    <Brand />
                </Sidenav.Header>
                <Sidenav expanded={expand} appearance="subtle" defaultOpenKeys={['2', '3']}>
                    <Sidenav.Body style={navBodyStyle}>
                        <Nav>
                            {navs.map(item => {
                                const { children, ...rest } = item;
                                if (children) {
                                    return (
                                        <Nav.Menu key={item.eventKey} placement="rightStart" trigger="click" {...rest}>
                                            {children.map(child => {
                                                return <NavItem key={child.eventKey} {...child} />;
                                            })}
                                        </Nav.Menu>
                                    );
                                }

                                if (rest.target === '_blank') {
                                    return (
                                        <Nav.Item key={item.eventKey} {...rest}>
                                            {item.title}
                                        </Nav.Item>
                                    );
                                }

                                return <NavItem key={rest.eventKey} {...rest} />;
                            })}
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
                <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
            </Sidebar>

            <Container className={containerClasses}>
                <Header1 />
                <Content>
                    <Outlet />
                </Content>
            </Container>
        </Container>
    );
};

export default Frame;
