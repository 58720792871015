import {Schema} from "rsuite";
const {StringType, NumberType, ArrayType, ObjectType, DateType} = Schema.Types;

const ProductCreateModel = Schema.Model({
    product_name: StringType().isRequired('This field is required.'),
    // seller: StringType().isRequired('This field is required.'),
    total_items: NumberType()
        .range(0, 1000, 'Total Items must be between 0 and 1000000.')
        .isRequired('This field is required.'),
    remaining_items: NumberType()
        .range(0, 1000000, 'Remaining Items must be between 0 and 1000000.')
        .isRequired('This field is required.'),
    purchase_price: NumberType()
        .range(1, 1000000, 'Purchase Price must be between 1 and 1000000.')
        .isRequired('This field is required.'),
    sell_price: NumberType()
        .range(1, 1000000, 'DC Charges must be between 1 and 1000000.')
        .isRequired('This field is required.'),

});

export default ProductCreateModel;