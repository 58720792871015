import {Col, Divider, Grid, Panel, Row, toaster} from "rsuite";
import React, {useRef, useState} from "react";
import {Form, ButtonToolbar, Button, Input} from 'rsuite';
import {auth} from "../../firebase";
import {InsertSingle, getAll} from "../../servies/firestore_service";
import ToasterMessage from "../../components/common/toaster_message";
import StockCreateModel from "../../form_models/stock_create_model";
import {stockCollectionName} from "../../config";


const StockCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const defaultFormValue = {
        deal_stock_name: '',
        stock_weight: 0.0,
        remaining_stock_weight: 0.0,
        purchase_price: 0.0,
        dc_charges: 0.0,
        seller_name: '',
        seller_phone: ''
    }
    const [formData, setFormData] = useState(defaultFormValue);

    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formData, 'Form Value')
    };


    const handleSubmit = async (e) => {

        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        setIsLoading(true);
        toaster.clear();



        InsertSingle(formData,stockCollectionName).then((docRef) => {
            console.log(docRef);
            ToasterMessage.success('Stock Created Successfully');
            setFormData(defaultFormValue);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            ToasterMessage.error('Error in creating Stock');
            setIsLoading(false);
        });

    }


    return (
        <Panel header={<h3 className="title">Create Stock</h3>}>
            <Form
                ref={formRef}
                onCheck={setFormError}
                formValue={formData}
                onChange={setFormData}
                model={StockCreateModel}
                disabled={isSubmitted}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="deal_name">
                                <Form.ControlLabel>Deal | Stock Name</Form.ControlLabel>
                                <Form.Control name="deal_stock_name" type="text" value={formData.deal_stock_name}/>
                                <Form.HelpText tooltip>Deal | Stock is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="stock_weight">
                                <Form.ControlLabel>Stock Weight</Form.ControlLabel>
                                <Form.Control name="stock_weight" type="number" value={formData.stock_weight}/>
                                <Form.HelpText tooltip>Stock Weight is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="stock_weight">
                                <Form.ControlLabel>Remaining Stock Weight</Form.ControlLabel>
                                <Form.Control name="remaining_stock_weight" type="number" value={formData.remaining_stock_weight}/>
                                <Form.HelpText tooltip>Remaining Stock Weight is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="purchase_price">
                                <Form.ControlLabel>Purchase Price</Form.ControlLabel>
                                <Form.Control name="purchase_price" type="number" value={formData.purchase_price}/>
                                <Form.HelpText tooltip>Purchase Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="dc_charges">
                                <Form.ControlLabel>DC Charges</Form.ControlLabel>
                                <Form.Control name="dc_charges" type="number" value={formData.dc_charges} />
                                <Form.HelpText tooltip>DC Charges is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="seller_name">
                                <Form.ControlLabel>Seller Name</Form.ControlLabel>
                                <Form.Control name="seller_name" value={formData.seller_name}/>
                                <Form.HelpText tooltip>Seller Name is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>

                            <Form.Group controlId="seller_phone">
                                <Form.ControlLabel>Seller Phone</Form.ControlLabel>
                                <Form.Control name="seller_phone" type="tel" value={formData.seller_phone}/>
                                <Form.HelpText tooltip>Seller Phone is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={handleSubmit}
                                            loading={isLoading}>Submit</Button>
                                    <Button appearance="default" onClick={()=>setFormData(defaultFormValue)}>Reset</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>

        </Panel>
    )
}

export default StockCreate;