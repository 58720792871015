import {Schema} from "rsuite";
const {StringType, NumberType, ArrayType, ObjectType, DateType} = Schema.Types;

const OrderCreateModel = Schema.Model({
    customer: StringType().isRequired('This field is required.'),
    // seller: StringType().isRequired('This field is required.'),
    total_price: NumberType()
        .range(1, 'Total Items must be between 1')
        .isRequired('This field is required.'),
    total_items: NumberType()
        .range(1, 1000000, 'Remaining Items must be between 1 and 1000000.')
        .isRequired('This field is required.'),
    dc_charges: NumberType()
        .range(0, 1000000, 'Purchase Price must be between 0 and 1000000.')
        .isRequired('This field is required.'),
    status: StringType()
        .isRequired('This field is required.'),
    courier_charges: NumberType()
        .range(0, 10000, 'Courier Charges must be between 0 and 10000.')
        .isRequired('This field is required.'),
    courier: StringType()
        .isRequired('This field is required.'),
    payment_status: StringType().isRequired('This field is required.'),
    tracking_number: StringType(),
    products: ArrayType().of(
        ObjectType().shape({
            id: StringType().isRequired('Required.'),
            qty: NumberType().isRequired('Required.')
        })
    )



});

export default OrderCreateModel;