import {auth, firestore} from "../firebase";
import {getDocs, getDoc, addDoc, collection} from 'firebase/firestore'
import { Firestore,doc } from "firebase/firestore";


const InsertSingle = async (data, collectionName) => {
    const currentTimestamp = Date.now();

    const docRef = await addDoc(collection(firestore, collectionName), {
        ...data,
        created_at: currentTimestamp,
        updated_at: currentTimestamp,
    });
    return docRef;
    console.log("Document written with ID: ", docRef.id);
}

const getAll = async (collectionName) => {
    const data = [];
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
        data.push({id: doc.id,...doc.data()});
    });
    return data;
}

const getSingle = async (id, collectionName) => {
    const docRef = doc(firestore, collectionName, id);

    const docSnap = await getSingle(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}


export  {InsertSingle, getAll,getSingle}