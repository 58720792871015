import React from 'react';
import {Icon} from '@rsuite/icons';
import DashboardIcon from '@rsuite/icons/Dashboard';
import EditIcon from '@rsuite/icons/Edit';
import CubesIcon from '@rsuite/icons/legacy/Cubes';
import * as faStore  from "@fortawesome/free-solid-svg-icons/faStore";
import * as faProductHunt  from "@fortawesome/free-brands-svg-icons/faProductHunt";
import * as faFirstOrderAlt  from "@fortawesome/free-brands-svg-icons/faFirstOrderAlt";



export const stockCollectionName = "stocks";
export const productCollectionName = "products";
export const orderCollectionName = "orders";
export const customerCollectionName = "customers";
export const orderStatusList = ['confirmed','booked', 'delivered', 'cancelled', 'dispatched', 'returned'];
export const paymentStatusList = ['due', 'paid'];
export const courierList = ['tcs', 'trax', 'leopards', 'swyft'];


const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
        <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
            <path d={svgPathData}></path>
        </svg>
    );
};

export const appNavs = [
    {
        eventKey: 'dashboard',
        icon: <Icon as={DashboardIcon}/>,
        title: 'Dashboard',
        to: '/dashboard'
    },
    {
        eventKey: 'Stock',
        icon: <Icon as={FaSvgIcon} faIcon={faStore}/>,
        title: 'Stock',
        to: '/stock',
        children: [
            {
                eventKey: 'stock-list',
                title: 'Stock List',
                to: '/stock'
            },
            {
                eventKey: 'stock-create',
                title: 'Stock Create',
                to: '/stock/create'
            },
            {
                eventKey: 'sell_order',
                title: 'Sell Stock | Order',
                to: '/stock/sell_order'
            }
        ]
    },
    {
        eventKey: 'products',
        icon: <Icon as={FaSvgIcon} faIcon={faProductHunt}/>,
        title: 'products',
        to: '/product',
        children: [
            {
                eventKey: 'products-list',
                title: 'Products List',
                to: '/product'
            },
            {
                eventKey: 'product-create',
                title: 'Product Create',
                to: '/product/create'
            },
        ]
    },
    {
        eventKey: 'orders',
        icon: <Icon as={FaSvgIcon} faIcon={faFirstOrderAlt}/>,
        title: 'Orders',
        to: '/order',
        children: [
            {
                eventKey: 'orders-list',
                title: 'Orders List',
                to: '/order'
            },
            {
                eventKey: 'order-create',
                title: 'Order Create',
                to: '/order/create'
            },
        ]
    },
    {
        eventKey: 'customers',
        icon: <Icon as={EditIcon}/>,
        title: 'Customers',
        to: '/customer',
        children: [
            {
                eventKey: 'customers-list',
                title: 'Customer List',
                to: '/customer'
            },
            {
                eventKey: 'customer-create',
                title: 'Customer Create',
                to: '/customer/create'
            },
        ]
    },
    {
        eventKey: 'components',
        title: 'Components',
        icon: <CubesIcon/>,
        href: 'https://rsuitejs.com/components/overview/',
        target: '_blank'
    }
];
