import {Col, Divider, Grid, Panel, Row, toaster} from "rsuite";
import React, {useRef, useState} from "react";
import {Form, ButtonToolbar, Button, Input} from 'rsuite';
import {auth} from "../../firebase";
import {InsertSingle, getAll} from "../../servies/firestore_service";
import ToasterMessage from "../../components/common/toaster_message";
import ProductCreateModel from "../../form_models/product_create_model";
import {productCollectionName} from "../../config";


const ProductCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const defaultFormValue = {
        product_name: '',
        total_items: 0,
        remaining_items: 0,
        purchase_price: 0.0,
        sell_price: 0.0,
        seller: '',
    }
    const [formData, setFormData] = useState(defaultFormValue);

    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formData, 'Form Value')
    };


    const handleSubmit = async (e) => {

        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        setIsLoading(true);
        toaster.clear();



        InsertSingle(formData,productCollectionName).then((docRef) => {
            console.log(docRef);
            ToasterMessage.success('Stock Created Successfully');
            setFormData(defaultFormValue);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            ToasterMessage.error('Error in creating Stock');
            setIsLoading(false);
        });

    }


    return (
        <Panel header={<h3 className="title">Create Product</h3>}>
            <Form
                ref={formRef}
                onCheck={setFormError}
                formValue={formData}
                onChange={setFormData}
                model={ProductCreateModel}
                disabled={isSubmitted}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="product_name">
                                <Form.ControlLabel>Product Name</Form.ControlLabel>
                                <Form.Control name="product_name" type="text" value={formData.product_name}/>
                                <Form.HelpText tooltip>Product Name is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="total_items">
                                <Form.ControlLabel>Total Items</Form.ControlLabel>
                                <Form.Control name="total_items" type="number" min='0' value={formData.total_items}/>
                                <Form.HelpText tooltip>Total Items is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        {/*<Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="stock_weight">
                                <Form.ControlLabel>Remaining Items</Form.ControlLabel>
                                <Form.Control name="remaining_items" type="number" min='0' value={formData.remaining_items}/>
                                <Form.HelpText tooltip>Remaining Items is required</Form.HelpText>
                            </Form.Group>
                        </Col>*/}
                    </Row>
                    <Row>

                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="purchase_price">
                                <Form.ControlLabel>Purchase Price</Form.ControlLabel>
                                <Form.Control name="purchase_price" type="number" value={formData.purchase_price}/>
                                <Form.HelpText tooltip>Purchase Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="sell_price">
                                <Form.ControlLabel>Sell Price</Form.ControlLabel>
                                <Form.Control name="sell_price" type="number" value={formData.sell_price}/>
                                <Form.HelpText tooltip>Sell Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="seller">
                                <Form.ControlLabel>Seller</Form.ControlLabel>
                                <Form.Control name="seller" value={formData.seller} disabled={true}/>
                                <Form.HelpText tooltip>Seller is required</Form.HelpText>
                            </Form.Group>
                        </Col>

                    </Row>

                    <br/>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={handleSubmit}
                                            loading={isLoading}>Submit</Button>
                                    <Button appearance="default" onClick={()=>setFormData(defaultFormValue)}>Reset</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>

        </Panel>
    )
}

export default ProductCreate;