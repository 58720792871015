// import './App.css';
import SignIn from "./pages/auth/sigin";
import './static/styles.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import {AppContext} from "./lib/contextLib";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from "./firebase";
import React, {useState, useEffect} from "react";
import {CustomProvider, Stack} from 'rsuite';
import Error404 from "./components/authentication/Error404";
import Home from "./pages/home";
import Frame from "./components/Frame";

import {appNavs} from "./config";
import CheckLogin from "./components/authentication/check_login";
import Dashboard from "./pages/dashboard";
import StockList from "./pages/stock/stock_list";
import StockCreate from "./pages/stock/stock_create";
import SellOrders from "./pages/stock/sell_orders";
import ProductCreate from "./pages/product/product_create";
import ProductList from "./pages/product/product_list";
import OrderList from "./pages/order/order_list";
import OrderCreate from "./pages/order/order_create";
import CustomerList from "./pages/customer/customer_list";
import CustomerCreate from "./pages/customer/customer_create";

function App() {
    const [authUser, setAuthUser] = useState(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                setUser(user);
            } else {
                setAuthUser(null);
                setUser(null);
                navigate("/login");
            }
        });
        return () => {
            listen();
        }
    }, []);


    return (
        <AppContext.Provider value={{user, setUser}}>
            <CustomProvider>
                <Routes>
                    <Route path="login" element={<SignIn/>}/>
                    <Route path="/" element={<CheckLogin child={<Frame navs={appNavs}/>}/>}>
                        <Route index element={<CheckLogin child={<Dashboard/>}/>}/>
                        <Route path='dashboard' element={<CheckLogin child={<Dashboard/>}/>}/>

                        <Route path='stock' element={<CheckLogin child={<StockList/>}/>}/>
                        <Route path='stock/create' element={<CheckLogin child={<StockCreate/>}/>}/>
                        <Route path='stock/sell_order' element={<CheckLogin child={<SellOrders/>}/>}/>

                        <Route path='customer' element={<CheckLogin child={<CustomerList/>}/>}/>
                        <Route path='customer/create' element={<CheckLogin child={<CustomerCreate/>}/>}/>

                        <Route path='order' element={<CheckLogin child={<OrderList/>}/>}/>
                        <Route path='order/create' element={<CheckLogin child={<OrderCreate/>}/>}/>

                        <Route path='product' element={<CheckLogin child={<ProductList/>}/>}/>
                        <Route path='product/create' element={<CheckLogin child={<ProductCreate/>}/>}/>

                        {/*<Route index element={<CheckLogin child={<Home />} />}/>*/}
                    </Route>
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </CustomProvider>


        </AppContext.Provider>

    );
}

export default App;
