import {Button, DOMHelper, Panel, Table} from 'rsuite';
import React, {useEffect, useState} from "react";
import {getSingle, getAll} from "../../servies/firestore_service";
import {productCollectionName} from "../../config";
import CustomDrawer from "../../components/common/custom_drawer";
import CustomerCreate from "../customer/customer_create";
import Order_create from "./order_create";
import OrderCreate from "./order_create";

const OrderList = () => {
    const {Column, HeaderCell, Cell} = Table;
    const {getHeight} = DOMHelper;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    useEffect(() => {
        setIsLoading(true)
        getAll(productCollectionName).then((docs) => {

            console.log(docs);
            setData(docs);
            setIsLoading(false)
        }).catch((error) => {
            console.log(error);
        })

    }, [])


    return (
        <Panel header={<h3 className="title">Customer List</h3>}>
            <Button appearance='primary' Style='margin-bottom: 10px' onClick={() => handleOpen('bottom')}>Create Order</Button>
            <CustomDrawer backdrop={'static'} size={'calc(100% - 80px)'} placement={placement} open={open} setOpen={setOpen} title='Create Order'>
                <OrderCreate/>
            </CustomDrawer>
            <Table
                virtualized
                height={Math.max(getHeight(window) - 120, 400)}
                data={data}
                translate3d={false}
                loading={isLoading}
            >
                <Column width={130}>
                    <HeaderCell>Product Name</HeaderCell>
                    <Cell dataKey="product_name"/>
                </Column>

                <Column width={130}>
                    <HeaderCell>Total Items</HeaderCell>
                    <Cell dataKey="total_items"/>
                </Column>

                <Column width={130}>
                    <HeaderCell>Remaining Items</HeaderCell>
                    <Cell dataKey="remaining_items"/>
                </Column>

                <Column width={100}>
                    <HeaderCell>Purchase Price</HeaderCell>
                    <Cell dataKey="purchase_price"/>
                </Column>

                <Column width={100}>
                    <HeaderCell>Sell Price</HeaderCell>
                    <Cell dataKey="sell_price"/>
                </Column>

                <Column width={200}>
                    <HeaderCell>Seller</HeaderCell>
                    <Cell dataKey="seller"/>
                </Column>

                <Column width={80} fixed="right">
                    <HeaderCell>...</HeaderCell>

                    <Cell style={{padding: '6px'}}>
                        {rowData => (
                            <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                Edit
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>
        </Panel>
    )
}

export default OrderList;
