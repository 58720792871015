import {
    Col, Divider, Grid, Panel, Row, toaster, SelectPicker, Form,
    Button, ButtonGroup, Schema, InputNumber, Input, FlexboxGrid, IconButto, ButtonToolbar, IconButton
} from "rsuite";
import React, {useEffect, useRef, useState} from "react";
import {auth} from "../../firebase";
import {InsertSingle, getAll} from "../../servies/firestore_service";
import ToasterMessage from "../../components/common/toaster_message";
import ProductCreateModel from "../../form_models/product_create_model";
import {
    courier, courierList,
    customerCollectionName,
    orderCollectionName,
    orderStatusList,
    paymentStatusList,
    productCollectionName
} from "../../config";
import CustomDrawer from "../../components/common/custom_drawer";
import ProductCreate from "../product/product_create";
import CustomerCreate from "../customer/customer_create";
import OrderCreateModel from "../../form_models/order_create_model";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";
import * as PropTypes from "prop-types";
import ProductInputControl from "../../components/common/product_input_control";


const OrderCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const defaultFormValue = {
        customer: '',
        total_price: 0.0,
        total_items: 0,
        dc_charges: 0.0,
        status: 'confirmed', //confirmed,booked, delivered, cancelled, dispatched, returned
        courier_charges: 0.0,
        payment_status: '', //paid, due
        courier: '', // tcs, trax, leopards,swyft
        tracking_number: '',
        products:[{id:'',qty:0}]
    }
    const [formData, setFormData] = useState(defaultFormValue);
    const [customerList, setCustomerList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
    const [isLoadingProduct, setIsLoadingProduct] = useState(false);


    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [chosenDrawer, setChosenDrawer] = useState('Product');

    const handleOpen = (key, drawerName) => {
        setOpen(true);
        setPlacement(key);
        setChosenDrawer(drawerName)
    };

    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formData, 'Form Value')
    };

    const handleSubmit = async (e) => {
        console.log(formData);
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        setIsLoading(true);
        toaster.clear();


        InsertSingle(formData, orderCollectionName).then((docRef) => {
            console.log(docRef);
            ToasterMessage.success('Created Successfully');
            setFormData(defaultFormValue);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            ToasterMessage.error('Error in creating');
            setIsLoading(false);
        });

    }

    const fetchAndSetProducts = () => {
        setIsLoadingProduct(true);
        getAll(productCollectionName)
            .then((docs) => {
                console.log(docs);
                const products = docs.map((doc) => {
                    return {
                        label: doc.product_name ? doc.product_name : '',
                        value: doc.id
                    }
                });

                console.log(products);

                setProductList(products);
                setIsLoadingProduct(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingProduct(false);
            });
    }

    const fetchAndSetCustomers = () => {
        setIsLoadingCustomer(true);
        getAll(customerCollectionName)
            .then((docs) => {
                console.log(docs);
                const customers = docs.map((doc) => {
                    return {
                        label: doc.customer_name ? doc.customer_name : '',
                        value: doc.id
                    }
                });

                console.log(customers);

                setCustomerList(customers);
                setIsLoadingCustomer(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingCustomer(false);
            });
    }


    useEffect(() => {
        fetchAndSetProducts();
        fetchAndSetCustomers();

    }, []);


    return (
        <Panel header={<h3 className="title">Create Order</h3>}>
            <Button appearance='primary' Style='margin: 10px' onClick={() => handleOpen('bottom', 'Product')}>Create
                Product</Button>
            <CustomDrawer backdrop={'static'} size={'calc(100% - 80px)'} placement={placement} open={open}
                          setOpen={setOpen} title={`Create ${chosenDrawer}`}>
                {chosenDrawer === 'Product' ? <ProductCreate/> : <CustomerCreate/>}
            </CustomDrawer>
            <Form
                ref={formRef}
                onCheck={setFormError}
                formValue={formData}
                onChange={setFormData}
                model={OrderCreateModel}
                disabled={isSubmitted}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8}>

                            <Form.Group controlId="customer">
                                <Form.ControlLabel>Select Customer</Form.ControlLabel>
                                <Form.Control name="customer"
                                              accepter={SelectPicker}
                                              data={customerList}
                                              value={formData.customer}
                                              style={{width: 300}}
                                              loading={isLoadingCustomer}
                                />
                                <Form.HelpText tooltip>Customer is required</Form.HelpText>

                                <Button appearance='primary' size='xs' style={{margin:10}} onClick={() => handleOpen('bottom', 'Customer')}>
                                    Create Customer
                                </Button>


                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="total_price">
                                <Form.ControlLabel>Total Price</Form.ControlLabel>
                                <Form.Control name="total_price" type="number" min='0' value={formData.total_price}/>
                                <Form.HelpText tooltip>Total Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="total_items">
                                <Form.ControlLabel>Total Items</Form.ControlLabel>
                                <Form.Control name="total_items" type="number" value={formData.total_items}/>
                                <Form.HelpText tooltip>Total Items is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="dc_charges">
                                <Form.ControlLabel>DC Charges</Form.ControlLabel>
                                <Form.Control name="dc_charges" type="number" value={formData.dc_charges}/>
                                <Form.HelpText tooltip>Sell Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="status">
                                <Form.ControlLabel>Status</Form.ControlLabel>
                                <Form.Control name="status"
                                              accepter={SelectPicker}
                                              data={orderStatusList.map(item => ({label: item.toUpperCase(), value: item}))}
                                              style={{width: 300}}
                                              value={formData.status}
                                />
                                <Form.HelpText tooltip>Status is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="payment_status">
                                <Form.ControlLabel>Payment Status</Form.ControlLabel>
                                <Form.Control name="payment_status"
                                              accepter={SelectPicker}
                                              data={paymentStatusList.map(item => ({label: item.toUpperCase(), value: item}))}
                                              style={{width: 300}}
                                              value={formData.payment_status}
                                />
                                <Form.HelpText tooltip>Status is required</Form.HelpText>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="courier">
                                <Form.ControlLabel>Courier</Form.ControlLabel>
                                <Form.Control name="courier"
                                              accepter={SelectPicker}
                                              data={courierList.map(item => ({label: item.toUpperCase(), value: item}))}
                                              style={{width: 300}}
                                              value={formData.courier}
                                />
                                <Form.HelpText tooltip>Status is required</Form.HelpText>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Divider style={{width: '70%'}}/>
                    <Button appearance='primary' size='sm' style={{margin:10}}  onClick={() => handleOpen('bottom', 'Product')}>
                        Create Product
                    </Button>

                    <Row>
                        <Form.Control
                            name="products"
                            accepter={ProductInputControl}
                            fieldError={formError.products}
                            productList = {productList}
                        />
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={handleSubmit}
                                            loading={isLoading}>Submit</Button>
                                    <Button appearance="default"
                                            onClick={() => setFormData(defaultFormValue)}>Reset</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </Panel>
    )
}

export default OrderCreate;