import {
    Form,
    ButtonToolbar,
    Button,
    toaster,
    Notification,
    Panel, Stack
} from 'rsuite'
import React, {forwardRef, useRef, useState} from "react";
import {createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../firebase";
import {useNavigate} from "react-router-dom";
import Brand from "../../components/Brand";
import ToasterMessage from "../../components/common/toaster_message";

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();
        toaster.clear();
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                const user = userCredential.user;
                console.log(user);
                navigate('/');
                setIsLoading(false);
            }
        ).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            ToasterMessage.error(errorMessage);
            setIsLoading(false);
            console.log(errorCode, errorMessage);
        });
    }

    if (user) {
        navigate("/");
        return null
    }


    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{
                height: '100vh'
            }}
        >
            <Brand style={{ marginBottom: 10 }} />

            <Panel bordered style={{ background: '#fff', width: 400 }} header={<h3>Sign In</h3>}>
                <Form fluid ref={formRef} onCheck={setFormError}>
                    <Form.Group controlId="email">
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="email" type="email" autoComplete="off" value={email}
                                      onChange={setEmail}/>
                        <Form.HelpText tooltip>Email is required</Form.HelpText>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <Form.Control name="password" type="password" autoComplete="off" value={password}
                                      onChange={setPassword}/>
                    </Form.Group>
                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={handleSubmit} loading={isLoading}>Sign
                                in</Button>
                            <Button appearance="link" disabled={true}>Forgot password?</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Panel>
        </Stack>

    );
}

export default SignIn


